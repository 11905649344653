/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Title, Text, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Ubytování a doprava"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--10 pl--25 pr--25 pt--10" name={"prazdna_sekcee"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1 --full mt--10 pb--10" columns={"1"} fullscreen={true}>
            
            <ColumnWrapper className="pb--25" style={{"paddingBottom":null}}>
              
              <Image className="swp-T00-mobile --right" alt={""} src={"https://cdn.swbpg.com/o/2124/8e308098c208425aac7816f98e602e91.svg"} svg={false} sizes="100vw" style={{"maxWidth":45}} srcSet={""} content={null} RootClassName={"column__pic"}>
              </Image>

              <Image className="--right mt--40" alt={""} src={"https://cdn.swbpg.com/o/2124/0939ff1d9f92402f8a460fa8a39064fd.svg"} svg={false} sizes="100vw" style={{"maxWidth":45,"marginTop":null}} srcSet={""} content={null} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"6fcvozgsqsk"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Title className="title-box" content={"Hlavní nadpis"}>
              </Title>

              <Text className="text-box" content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, řádkování, nebo zarovnat text."}>
              </Text>

              <Button className="btn-box" content={"Tlačítko"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Title className="title-box" content={"Hlavní nadpis"}>
              </Title>

              <Text className="text-box" content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, řádkování, nebo zarovnat text."}>
              </Text>

              <Button className="btn-box" content={"Tlačítko"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}